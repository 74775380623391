import logoPng from '@/assets/logo.png';
import mulheresEscritorio from '@/assets/pessoas/pessoa.png';
import projeto1FotoAntes from '../../assets/portifolio/projeto1/fotoAntes.jpeg';
import projeto1FotoDepois from '../../assets/portifolio/projeto1/fotoDepois.jpeg';
import projeto2FotoAntes from '../../assets/portifolio/projeto2/fotoAntes.jpg';
import projeto2FotoDepois from '../../assets/portifolio/projeto2/fotoDepois.jpg';
import projeto3FotoAntes from '../../assets/portifolio/projeto3/fotoAntes.jpg';
import projeto3FotoDepois from '../../assets/portifolio/projeto3/fotoDepois.jpg';
import projeto4FotoAntes from '../../assets/portifolio/projeto4/fotoAntes.jpg';
import projeto4FotoDepois from '../../assets/portifolio/projeto4/fotoDepois.jpg';
import projeto5FotoAntes from '../../assets/portifolio/projeto5/fotoAntes.jpg';
import projeto5FotoDepois from '../../assets/portifolio/projeto5/fotoDepois.jpg';
import projeto6FotoAntes from '../../assets/portifolio/projeto6/fotoAntes.jpg';
import projeto6FotoDepois from '../../assets/portifolio/projeto6/fotoDepois.jpg';
import projeto7FotoAntes from '../../assets/portifolio/projeto7/fotoAntes.jpg';
import projeto7FotoDepois from '../../assets/portifolio/projeto7/fotoDepois.jpg';
import projeto8FotoAntes from '../../assets/portifolio/projeto8/fotoAntes.jpg';
import projeto8FotoDepois from '../../assets/portifolio/projeto8/fotoDepois.jpg';
import projeto9FotoAntes from '../../assets/portifolio/projeto9/fotoAntes.jpg';
import projeto9FotoDepois from '../../assets/portifolio/projeto9/fotoDepois.jpg';
import projeto10FotoAntes from '../../assets/portifolio/projeto10/fotoAntes.jpeg';
import projeto10FotoDepois from '../../assets/portifolio/projeto10/fotoDepois.jpg';
import projeto11FotoAntes from '../../assets/portifolio/projeto11/fotoAntes.jpg';
import projeto11FotoDepois from '../../assets/portifolio/projeto11/fotoDepois.jpg';
import foto2 from '../../assets/portifolio/outros/foto2.jpg';
import foto3 from '../../assets/portifolio/outros/foto3.jpg';
import foto5 from '../../assets/portifolio/outros/foto5.jpg';
import foto6 from '../../assets/portifolio/outros/foto6.jpg';
import foto7 from '../../assets/portifolio/outros/foto7.jpg';
import foto8 from '../../assets/portifolio/outros/foto8.jpeg';
import foto11 from '../../assets/portifolio/outros/foto11.jpg';
import foto12 from '../../assets/portifolio/outros/foto12.jpg';

// relatos
import relato1 from '../../assets/pessoas/relatos/relato1.png';
import relato2 from '../../assets/pessoas/relatos/relato2.png';
import relato3 from '../../assets/pessoas/relatos/relato3.png';
import relato4 from '../../assets/pessoas/relatos/relato4.png';

// backgrounds
import bgCasa2 from '../../assets/background/casa2.jpg';

// Piso
import piso from '../../assets/portifolio/piso.jpg';

import svgGoogle from '../../assets/SVG/google.svg';

export default {
    logo:logoPng,
    pessoas:{
        mulheresEscritorio:mulheresEscritorio
    },
    outras:{
        piso:piso
    },
    portifolio:[
        [projeto1FotoAntes,projeto1FotoDepois],
        [projeto2FotoAntes,projeto2FotoDepois],
        [projeto3FotoAntes,projeto3FotoDepois],
        [projeto7FotoAntes,projeto7FotoDepois],
        [projeto6FotoAntes,projeto6FotoDepois],
        [projeto11FotoAntes,projeto11FotoDepois],
        [projeto8FotoAntes,projeto8FotoDepois],
        [foto2,foto6],
        [projeto10FotoAntes,projeto10FotoDepois],
        [projeto5FotoAntes,projeto5FotoDepois],
        [foto5,foto7],
        [projeto4FotoAntes,projeto4FotoDepois],
        [projeto9FotoAntes,projeto9FotoDepois],
        [foto3],
        [foto8],
        [foto11],
        [foto12]
    ],
    relatos:{
        relato1:relato1,
        relato2:relato2,
        relato3:relato3,
        relato4:relato4
    },
    background:{
        bgCasa2:bgCasa2
    },
    svgs:{
        svgGoogle:svgGoogle
    }
}