export default {
    primaria:{
        nome:'black',
        nomeBg:'bg-black',
        nomeText:'text-black',
        hexa:'#424242'
    },
    secondaria:{
        nome:'blue-grey-darken-2',
        nomeBg:'bg-blue-grey-darken-2',
        nomeText:'text-blue-grey-darken-2',
        hexa:'#455A64'
    },
    grey:{
        nome:'blue-grey-lighten-5',
        nomeBg:'bg-blue-grey-lighten-5',
        nomeText:'text-blue-grey-lighten-5',
        hexa:'#FAFAFA'
    }
}