<template>
  <div :style="isMobile ? 'max-width:100vw;overflow-x:hidden' : ''">
    <!-- Menu -->
    <div>
      <v-row :class="tema.primaria.nomeBg">
        <v-col cols="12">
          <v-container v-if="!isMobile">
            <v-row>
              <v-col cols="2">
                <v-row>
                  <v-col>
                    <v-img width="80" :src="imgs.logo"></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="8" class="d-flex align-center py-0">
                <v-row>
                  <v-col>
                    <v-btn @click="scrollToElement('home')" block variant="text"
                      >Home</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="scrollToElement('servicos')"
                      block
                      variant="text"
                      >Serviços</v-btn
                    >
                  </v-col>
                  <!-- <v-col>
                    <v-btn block variant="text">Sobre</v-btn>
                  </v-col> -->
                  <v-col>
                    <v-btn
                      @click="scrollToElement('portifolio')"
                      block
                      variant="text"
                      >Portifólio</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="scrollToElement('depoimentos')"
                      block
                      variant="text"
                      >Depoimentos</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="scrollToElement('formulario')"
                      block
                      variant="text"
                      >Contato</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="d-flex align-center py-0">
                <v-row>
                  <v-col cols="4 px-0">
                    <v-btn
                      size="large"
                      variant="icon"
                      @click="
                        openUrl(
                          'https://api.whatsapp.com/send?phone=5511994806839'
                        )
                      "
                      icon="mdi-whatsapp"
                      class="bg-green"
                    ></v-btn>
                  </v-col>
                  <v-col cols="4 px-0">
                    <v-btn
                      size="large"
                      variant="icon"
                      @click="
                        openUrl('https://www.instagram.com/lmsengenhariaofc/')
                      "
                      icon="mdi-instagram"
                      class="bg-deep-orange-accent-3"
                    ></v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col class="d-flex align-center py-0">
                <v-switch
                  class="d-flex align-center py-0"
                  :model-value="tela.interacoes.modoDark"
                  color="white"
                ></v-switch>
              </v-col> -->
            </v-row>
          </v-container>
          <v-container v-if="isMobile">
            <v-row>
              <v-col cols="7">
                <v-row>
                  <v-col>
                    <v-img width="80" :src="imgs.logo"></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="1" class="d-flex align-center py-0">
                <v-switch
                  class="d-flex align-center py-0"
                  :model-value="tela.interacoes.modoDark"
                  color="white"
                ></v-switch>
              </v-col> -->
              <v-col cols="5" class="py-0 d-flex align-center justify-end">
                <v-row>
                  <v-col cols="12 d-flex align-center justify-end">
                    <div>
                      <v-menu
                        v-model="tela.interacoes.menuMobile"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ props }">
                          <v-row>
                            <v-col>
                              <v-btn
                                v-bind="props"
                                variant="icon"
                                icon="mdi-menu"
                              ></v-btn>
                            </v-col>
                          </v-row>
                        </template>
                        <v-list
                          elevation="0"
                          style="background: transparent; width: 70vw"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-btn
                                rounded
                                @click="scrollToElement('home')"
                                block
                                elevation="0"
                                :color="tema.primaria.nome"
                              >
                                Home
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-btn
                                rounded
                                @click="scrollToElement('servicos')"
                                block
                                elevation="0"
                                :color="tema.primaria.nome"
                              >
                                Serviços
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                          <!-- <v-list-item>
                            <v-list-item-content>
                              <v-btn rounded @click="null" block elevation="0" :color="tema.primaria.nome">
                                Sobre
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item> -->
                          <v-list-item>
                            <v-list-item-content>
                              <v-btn
                                rounded
                                @click="scrollToElement('portifolio')"
                                block
                                elevation="0"
                                :color="tema.primaria.nome"
                              >
                                Portifólio
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-btn
                                rounded
                                @click="scrollToElement('depoimentos')"
                                block
                                elevation="0"
                                :color="tema.primaria.nome"
                              >
                                Depoimentos
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-btn
                                rounded
                                @click="scrollToElement('formulario')"
                                block
                                elevation="0"
                                :color="tema.primaria.nome"
                              >
                                Contato
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </div>
    <!-- MID -->
    <div id="home" class="bg-mid-imagem" style="height: 500px">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col :cols="isMobile ? 12 : 6" class="mt-5 mb-5">
            <v-row class="h-100">
              <v-col cols="12 text-bold text-white default-mouse"
                :class="isMobile ? 'text-h4 d-flex justify-center' : 'text-h4'"
              >
                LMS Engenharia e Construções
              </v-col>
              <v-col
                cols="12 text-white default-mouse"
                :class="
                  isMobile ? 'text-h6 d-flex justify-center' : 'text-h5'
                "
                :style="isMobile ? 'text-align: center;' : ''"
              >
                Empresa focada em gerenciamento e execução de obras, projetos
                arquitetônicos e de interiores. Oferecendo qualidade, inovação,
                planejamento e custo benefício ao cliente
              </v-col>
              <v-col cols="12 d-flex align-end">
                <v-btn
                  block
                  @click="
                    openUrl('https://api.whatsapp.com/send?phone=5511994806839')
                  "
                  prepend-icon="mdi-whatsapp"
                  rounded
                  height="50"
                  color="green"
                >
                  Entre em contato
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="isMobile ? 12 : 6">
            <v-row class="d-flex align-center justify-center h-100">
              <v-col :cols="isMobile ? 8 : 6">
                <v-img
                  style="z-index: 1000"
                  cover
                  :src="imgs.pessoas.mulheresEscritorio"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Serviços -->
    <div id="servicos" :class="isMobile ? 'default-mouse' : 'default-mouse'"
      :style="isMobile?'margin-top:150px;':''">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col
            v-for="(servico, index) in tela.servicos"
            :key="index"
            :cols="isMobile ? 12 : 5"
            class="py-0 d-flex justify-center"
          >
            <v-card
              elevation="0"
              :height="isMobile ? null : 430"
              :color="tema.primaria.secondaria"
              class="pt-5 px-2"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12 d-flex justify-center py-0">
                    <v-icon size="100">
                      {{ servico.icone }}
                    </v-icon>
                  </v-col>
                  <v-col
                    :cols="
                      '12 d-flex justify-center text-h6 ' +
                      tema.primaria.nomeText
                    "
                  >
                    {{ servico.titulo }}
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(tipo, index) in servico.tipos"
                    :key="index"
                    cols="12 py-1"
                    class="text-hover text-subtitle-1"
                  >
                    <v-icon>mdi-check</v-icon>
                    <span :class="tema.primaria.nomeText">{{ tipo }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- historia -->
    <div id="historia" :class="tema.primaria.nomeBg + ' default-mouse'">
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex justify-center text-caption">
            [SOBRE A LMS]
          </v-col>
          <v-col v-if="isMobile" cols="12 py-0">
            <v-row>
              <v-col cols="12 d-flex justify-center mt-3 pb-0 text-h5">
                Quem somos
              </v-col>
              <v-col cols="12 d-flex justify-center text-body-1">
                A LMS engenharia é uma empresa que traz em sua trajetória
                inovação e responsabilidade. Prezamos pela qualidade acima de
                tudo e trabalhamos de maneira diferenciada, sob medida para cada
                cliente. A LMS oferece serviços que abordam todas as etapas da
                obra, como: consultoria, estudos de viabilidade, elaboração do
                projeto, construções e reformas.
              </v-col>
              <v-col cols="12 d-flex justify-center">
                <v-img height="150" :src="imgs.logo"></v-img>
              </v-col>
              <v-col cols="12 d-flex justify-center">
                <v-btn
                  @click="
                    openUrl('https://api.whatsapp.com/send?phone=5511994806839')
                  "
                  color="green"
                  prepend-icon="mdi-whatsapp"
                  width="250"
                  height="40"
                  >Solicite seu orçamento</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            <v-row>
              <v-col cols="6 d-flex align-center justify-center">
                <v-img height="300" :src="imgs.logo"></v-img>
              </v-col>
              <v-col cols="6 d-flex align-center justify-center">
                <v-row>
                  <v-col cols="12 text-h4 d-flex justify-center mb-3">
                    Quem somos
                  </v-col>
                  <v-col cols="12 d-flex justify-center" style="font-size:26px">
                    A LMS engenharia é uma empresa que traz em sua trajetória
                    inovação e responsabilidade. Prezamos pela qualidade acima
                    de tudo e trabalhamos de maneira diferenciada, sob medida
                    para cada cliente. A LMS oferece serviços que abordam todas
                    as etapas da obra, como: consultoria, estudos de
                    viabilidade, elaboração do projeto, construções e reformas.
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12 d-flex justify-center">
                <v-btn
                  @click="
                    openUrl('https://api.whatsapp.com/send?phone=5511994806839')
                  "
                  color="green"
                  prepend-icon="mdi-whatsapp"
                  width="450"
                  height="40"
                  >Solicite seu orçamento</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Portifolio -->
    <div id="portifolio" :class="tema.primaria.nomeBg + ' default-mouse'">
      <v-container>
        <v-row class="mb-5">
          <v-col cols="12" class="d-flex justify-center text-caption mt-5 pb-0">
            [NOSSO PORTFÓLIO]
          </v-col>
          <v-col
            cols="12"
            :class="
              'd-flex justify-center ' + (isMobile ? 'text-h5' : 'text-h4')
            "
          >
            Conheça Nossos Projetos
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col
                v-for="(port, index) in tela.portifolio"
                :key="index"
                class="px-1"
                :cols="isMobile ? 4 : 3"
              >
                <v-card
                  style="background: transparent;position:relative;"
                  @click="contexto.visualizandoImagens = true"
                  class="hover-image"
                >
                  <v-img v-if="port && port.length>1" :src="port[0]"
                    height="170" cover
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                  </v-img>
                  <v-img :src="port && port.length>1?port[1]:port[0]"
                    height="230" cover >
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Formulario -->
    <div id="formulario" class="bg-formulario-imagem default-mouse">
      <v-container class="pa-5">
        <v-row>
          <v-col
            cols="12 d-flex justify-center text-h4 pb-0 text-bold text-uppercase"
            :class="isMobile ? 'text-h6 text-white' : 'text-h4 text-white'"
          >
            Você pensa, nós idealizamos.
          </v-col>
          <v-col
            cols="12 d-flex justify-center py-0 text-white"
            class="text-h5"
          >
            Solicite já um orçamento
          </v-col>
          <v-col cols="12 d-flex justify-center">
            <v-card class="w-100 background-transparent">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        variant="underlined"
                        :color="white"
                        v-model="contexto.inputs.nome"
                        label="Nome"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        variant="underlined"
                        :color="white"
                        v-model="contexto.inputs.email"
                        label="E-mail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        variant="underlined"
                        :color="white"
                        v-model="contexto.inputs.telefone"
                        label="Telefone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        variant="underlined"
                        :color="white"
                        v-model="contexto.inputs.mensagem"
                        label="Mensagem"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        block
                        color="green"
                        :disabled="
                          !contexto.inputs.mensagem ||
                          !contexto.inputs.telefone ||
                          !contexto.inputs.nome ||
                          !contexto.inputs.email
                        "
                        @click="enviarFormulario('5511994806839')"
                      >
                        ENVIAR FORMULÁRIO
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Instagram -->
    <div id="instagram">
      <v-container>
        <v-row>
          <v-col cols="12 d-flex justify-center">
            <blockquote class="instagram-media" data-instgrm-permalink="https://www.instagram.com/lmsengenhariaofc/" data-instgrm-version="12" style="width:100%; min-width:326px; background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; padding:0;">
                <div style="padding:16px;">
                    <a href="https://www.instagram.com/lmsengenhariaofc/" style=" color:#000; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:normal; line-height:17px; text-decoration:none; word-wrap:break-word;" target="_blank">
                        <!-- Nome do perfil e outros detalhes aparecerão automaticamente aqui -->
                    </a>
                </div>
            </blockquote>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Porque contratar -->
    <div id="porqueContratar" class="default-mouse">
      <v-container>
        <v-row>
          <v-col cols="12 d-flex justify-center" :class="isMobile?'text-subtitle-1':'text-subititle-1'">
            Serviço de Construções em São Paulo e Região 
          </v-col>
          <v-col cols="12 d-flex justify-center py-0" :class="isMobile?'text-h6':'text-h5'">
            Por que contratar a LMS Engenharia & Construções ?
          </v-col>
          <v-col :cols="isMobile?12:6">
            <v-row>
              <v-col cols="12">
                <v-img class="rounded" :src="imgs.outras.piso"></v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="isMobile?12:6">
            <v-row>
              <v-col cols="12 d-flex justify-center" v-for="porque,index in tela.porqueContratar" :key="index">
                <v-card elevation="1" @mouseover="porque.hover=true" @mouseleave="porque.hover=false">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col :cols="isMobile?3:2">
                          <v-chip :color="porque.hover?'blue-darken-1':null">
                            <span class="text-h6 text-bold">{{(index+1)}}</span>
                          </v-chip>
                        </v-col>
                        <v-col :cols="isMobile?9:10">
                          <v-row>
                            <v-col cols="12 py-0" :class="isMobile?'text-subtitle-2':'text-h6'">
                              {{porque.titulo}}
                            </v-col>
                            <v-col cols="12 py-0 text-caption">
                              {{porque.descricao}}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Relatos -->
    <div id="depoimentos" class="default-mouse">
      <!-- <v-container>
        <v-row>
          <v-col cols="12">
            <slide-x-transition>
              <v-card v-if="relatoAtivo" :key="relatoAtivo.id">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-img height="120" :src="relatoAtivo.foto"></v-img>
                      </v-col>
                      <v-col cols="12">
                        <v-container>
                          <v-row>
                            <v-col cols="12 d-flex justify-center text-h5 py-0">
                              {{ relatoAtivo.nome }}
                            </v-col>
                            <v-col cols="12 d-flex justify-center text-h5 py-0">
                              <v-col
                                :cols="isMobile ? 12 : 6"
                                class="d-flex justify-end align-center"
                              >
                                <v-icon
                                  :size="isMobile ? 20 : 30"
                                  color="orange"
                                  >mdi-star</v-icon
                                >
                                <v-icon
                                  :size="isMobile ? 20 : 30"
                                  color="orange"
                                  >mdi-star</v-icon
                                >
                                <v-icon
                                  :size="isMobile ? 20 : 30"
                                  color="orange"
                                  >mdi-star</v-icon
                                >
                                <v-icon
                                  :size="isMobile ? 20 : 30"
                                  color="orange"
                                  >mdi-star</v-icon
                                >
                                <v-icon
                                  :size="isMobile ? 20 : 30"
                                  color="orange"
                                  class="mr-4"
                                  >mdi-star</v-icon
                                >
                              </v-col>
                              <v-col
                                :cols="isMobile ? 12 : 6"
                                class="d-flex justify-start align-center"
                              >
                                <v-chip :color="tema.primaria.nome">{{
                                  relatoAtivo.tempo
                                }}</v-chip>
                              </v-col>
                            </v-col>
                            <v-col
                              cols="12 d-flex justify-center text-center textpcation"
                            >
                              {{ relatoAtivo.comentario }}
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </slide-x-transition>
          </v-col>
          <v-col cols="12 d-flex justify-center">
            <v-icon
              v-for="(tela, index) in tela.relatos"
              :key="index"
              :color="tema.primaria.nome"
              class="cursor-pointer"
              @mouseover="tela.mouseover = true"
              @click="alterarRelatoAtivo(tela)"
              @mouseleave="tela.mouseover = false"
            >
              {{
                tela.ativo || tela.mouseover
                  ? "mdi-circle"
                  : "mdi-circle-outline"
              }}
            </v-icon>
          </v-col>
        </v-row>
      </v-container> -->
      <v-container>
        <v-row>
          <v-col :cols="isMobile?12:4" v-for="(relato, index) in tela.relatos" :key="index">
            <v-card color="grey-lighten-4" elevation="0" min-height="340">
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- Header -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="4">
                          <v-img height="50" :src="relato.foto"></v-img>
                        </v-col>
                        <v-col cols="8 d-flex align-center">
                          <v-row>
                            <v-col cols="12 text-body-1 py-0">
                              {{relato.nome}}
                            </v-col>
                            <v-col cols="12 text-caption py-0">
                              {{relato.tempo}}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- Estrelas -->
                    <v-col cols="12 py-1">
                      <v-icon size="30" color="orange">mdi-star</v-icon>
                      <v-icon size="30" color="orange">mdi-star</v-icon>
                      <v-icon size="30" color="orange">mdi-star</v-icon>
                      <v-icon size="30" color="orange">mdi-star</v-icon>
                      <v-icon size="30" color="orange">mdi-star</v-icon>
                    </v-col>
                    <!-- Texto -->
                    <v-col cols="12 py-0 text-subtitle-1">
                      {{ relato.comentario && relato.comentario.length>110 && !relato.verMais?relato.comentario.substring(0,110):relato.comentario }}
                    </v-col>
                    <v-col cols="12 d-flex justify-center" v-if="relato.comentario && relato.comentario.length>110">
                      <v-btn @click="relato.verMais = !relato.verMais"
                      density="compact" variant="text">{{relato.verMais?'Ocultar':'Consulte mais informações'}}</v-btn>
                    </v-col>
                    <!-- Postado -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="2">
                          <v-img :src="imgs.svgs.svgGoogle"></v-img>
                        </v-col>
                        <v-col cols="8 d-flex justify-center align-center">
                          <v-row>
                            <v-col cols="12 text-gray text-caption py-0">
                              postado em
                            </v-col>
                            <v-col cols="12 text-blue text-body-1 py-0">
                              <a style="text-decoration:none; color:blue" href="https://www.google.com/maps/place/LMS+Engenharia+%26+Constru%C3%A7%C3%B5es/@-23.4871928,-46.6070892,15z/data=!4m2!3m1!1s0x0:0x618bc5a00f09e6ac?sa=X&ved=1t:2428&ictx=111">Google</a>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Rodapé -->
    <div id="rodape" class="default-mouse">
      <v-container class="pa-10">
        <v-row>
          <v-col
            :cols="isMobile ? 12 : 8"
            :class="
              isMobile
                ? 'text-h5 d-flex justify-center'
                : 'text-h4 d-flex justify-center align-center'
            "
          >
            Conte sua história ...
          </v-col>
          <v-col
            :cols="isMobile ? 12 : 4"
            :class="
              isMobile
                ? 'd-flex justify-center align-center'
                : 'd-flex justify-start align-center'
            "
          >
            <v-btn
              @click="
                openUrl('https://api.whatsapp.com/send?phone=5511994806839')
              "
              block
              :color="tema.primaria.nome"
              height="70"
              >FAÇA SEU ORÇAMENTO</v-btn
            >
          </v-col>
          <v-col
            :cols="isMobile ? 12 : 6"
            :class="
              isMobile
                ? 'd-flex justify-center'
                : 'd-flex justify-center align-start'
            "
          >
            <v-row>
              <v-col :cols="isMobile ? 12 : 5">
                <v-row>
                  <v-col cols="12 d-flex justify-center">
                    <v-icon size="70" color="red"> mdi-map-marker </v-icon>
                  </v-col>
                  <v-col cols="12 d-flex justify-center py-0">
                    <span class="text-h6">ENDEREÇO</span>
                  </v-col>
                  <v-col cols="12 text-underline d-flex justify-center py-0">
                    <span>Av. Gen. Ataliba Leonel, 3319</span>
                  </v-col>
                  <v-col cols="12 text-underline d-flex justify-center py-0">
                    <span>Tucuruvi, São Paulo - SP</span>
                  </v-col>
                  <v-col cols="12 text-underline d-flex justify-center py-0">
                    <span>02242-001</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col :cols="isMobile ? 12 : 7" class="d-flex justify-center align-center">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.258612738875!2d-46.607089200000004!3d-23.4871928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef611467f7cc7%3A0x743af2bc4f875f3e!2sAv.%20Gen.%20Ataliba%20Leonel%2C%203319%20-%20Tucuruvi%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2002242-001!5e0!3m2!1spt-BR!2sbr!4v1719870281350!5m2!1spt-BR!2sbr" 
                :width="isMobile?300:300" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :cols="isMobile ? 12 : 3"
            :class="
              isMobile
                ? 'd-flex justify-center'
                : 'd-flex justify-center align-start'
            "
          >
            <v-row>
              <v-col cols="12 d-flex justify-center">
                <v-icon size="70" color="orange"> mdi-mail </v-icon>
              </v-col>
              <v-col cols="12 d-flex justify-center py-0">
                <span class="text-h6">E-MAIL</span>
              </v-col>
              <v-col cols="12 text-underline d-flex justify-center py-0">
                <span>Lms.eng@outlook.com.br</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :cols="isMobile ? 12 : 3"
            :class="
              isMobile
                ? 'd-flex justify-center'
                : 'd-flex justify-center align-center'
            "
          >
            <v-row>
              <v-col cols="12 d-flex justify-center">
                <v-icon size="70" color="green"> mdi-whatsapp </v-icon>
              </v-col>
              <v-col cols="12 d-flex justify-center py-0">
                <span class="text-h6">TELEFONE</span>
              </v-col>
              <v-col cols="12 text-underline d-flex justify-center py-0">
                <span>(11) 99480-6839</span>
              </v-col>
              <v-col cols="12 text-underline d-flex justify-center py-0">
                <span>(11) 99313-1205</span>
              </v-col>
              <br/>
              <v-col cols="12 text-underline d-flex justify-center py-0">
                <span>Segunda a Sexta 8h às 18h</span>
              </v-col>
              <v-col cols="12 text-underline d-flex justify-center py-0">
                <span>Sábado 8h às 12h</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-row>
        <v-col
          :cols="isMobile ? 12 : 6"
          :class="tema.primaria.nomeBg + ' d-flex justify-center align-center'"
        >
          2024 @ LMS | Todos os direitos reservados | Desenvolvido por
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 6"
          :class="tema.primaria.nomeBg + ' d-flex justify-center align-center'"
        >
          <v-btn
            variant="text"
            @click="openUrl('https://www.instagram.com/biel__andradee/')"
            >Desenvolvedor</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <!-- Botão flutuante -->
    <div
      :style="
        isMobile
          ? 'position: fixed; bottom: 65px; right: 20px;z-index:1000'
          : 'position: fixed; bottom: 20px; right: 20px;z-index:1000'
      "
    >
      <v-row>
        <v-col cols="12 d-flex justify-end">
          <v-btn
            rounded
            elevation="10"
            color="green"
            height="50"
            @click="
              openUrl('https://api.whatsapp.com/send?phone=5511994806839')
            "
          >
            <v-icon size="30">mdi-whatsapp</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- Dialog de visualizar fotos -->
    <v-dialog
      v-model="contexto.visualizandoImagens"
      :class="isMobile ? 'w-100' : 'w-50'"
    >
      <v-container>
        <v-row>
          <v-col cols="12 d-flex justify-center align-center">
            <v-card
              class="w-100"
              style="background: rgba(255, 255, 255, 0)"
              elevation="0"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12 px-0 py-0">
                    <v-carousel :color="tema.primaria.nome">
                      <v-carousel-item
                        v-for="(foto, index) in portifolio"
                        :key="index"
                        :src="foto"
                      ></v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import imgs from "@/resources/imagens/index.js";
import tema from "@/resources/tema/index.js";
export default defineComponent({
  name: "HomeView",
  data: () => ({
    imgs: { ...imgs },
    tema: { ...tema },
    contexto: {
      visualizandoImagens: false,
      inputs: {
        nome: null,
        email: null,
        telefone: null,
        mensagem: null,
      },
    },
    tela: {
      interacoes: {
        menuMobile: false,
        modoDark: true,
      },
      servicos: [
        {
          titulo: "PROJETOS",
          icone: "mdi-clipboard-text-outline",
          tipos: [
            "Projeto Arquitetônico",
            "Projeto Interiores",
            "Projeto 3D",
            "Projeto Executivo",
          ],
        },
        {
          titulo: "OBRAS",
          icone: "mdi-account-hard-hat",
          tipos: [
            "Gerenciamento de obras (orçamento, cronograma e acompanhamento)",
            "Execução de obra residencial, comercial e corporativo com equipe técnica especializada",
            "Emissão de RT, laudos técnicos, documentação e regularização na prefeitura",
            "Vistoria pós-obra",
          ],
        },
      ],
      porqueContratar: [
        {
          titulo:'Acompanhamento de obra',
          descricao:'Serviço eficiente com acompanhamento do engenheiro responsável. Garantindo qualidade e satisfação ao cliente.',
          hover:false
        },
        {
          titulo:'Equipe Qualificada',
          descricao:'Profissionais altamente qualificados para execução do serviço.',
          hover:false
        },
        {
          titulo:'Agilidade na execução da obra',
          descricao:'Garantia de satisfação com o prazo de inicialização e finalização do projeto.',
          hover:false
        }
      ],
      portifolio: [...imgs.portifolio],
      relatos: [
        {
          id: 1,
          foto: imgs.relatos.relato1,
          ativo: true,
          mouseover: false,
          nome: "Milena Mendes",
          tempo: "5 dias atrás",
          comentario:
            "Empresa honesta e competente!! O engenheiro Leonardo é atencioso e muito educado. Equipe ágil! Recomendo para quem procura por mão de obra qualificada!",
        },
        {
          id: 2,
          foto: imgs.relatos.relato2,
          ativo: false,
          mouseover: false,
          nome: "Ivete mendes santos",
          tempo: "uma semana atrás",
          comentario:
            "Ótima empresa!!!",
        },
        {
          id: 3,
          foto: imgs.relatos.relato3,
          ativo: false,
          mouseover: false,
          nome: "Adriele Almeida",
          tempo: "há 4 semanas",
          comentario:"Empresa com excelentes parcerias e profissionais. Sempre muito satisfeita com trabalho executado."
        },
        {
          id: 4,
          foto: imgs.relatos.relato4,
          ativo: false,
          mouseover: false,
          nome: "Stefano Ragazzi",
          tempo: "há 3 meses",
          comentario:"Recomendo de olhos fechados! Fiz o projeto com a Arquiteta Ana que me indicou a LMS. O engenheiro Leonardo foi muito atencioso e me explicou todos os detalhes possíveis de como seria feito o serviço dele com sua equipe, passando desde o início muita autonomia e confiança no que estava falando.Acabamento de extrema qualidade, os 45 graus do nicho e da cozinha ficaram perfeitos, pintura em efeito cimento queimado muito bem executado! A organização e acompanhamento do Leonardo junto a sua equipe  eu gostei muito! pois já tive problemas no meu outro apartamento pois o outro engenheiro deixava a equipe dele lá e só voltava no último dia da obra e isso só meu deu dor de cabeça.Agradeço a LMS por todo comprometimento com um pouquinho do meu sonho! Obrigado Leonardo, sucesso! Toda indicação que me pedirem já vem sua empresa em minha mente kkk"
        }
      ],
    },
  }),
  methods: {
    inicializarContextoAcoesNavegador() {
      let vueCtx = this;
      window.addEventListener("popstate", function (event) {
        if (vueCtx.contexto.visualizandoImagens) {
          vueCtx.contexto.visualizandoImagens = false;
        }
        history.pushState(null, null, location.href);
      });
      history.pushState(null, null, location.href);
    },
    enviarFormulario(telefone) {
      let mensagem = "";
      mensagem = this.contexto.inputs.mensagem;
      // + " \n " + "*Dados para contato*\n" +
      // "Nome: " + this.contexto.inputs.nome + "\n"
      // "Telefone: " + this.contexto.inputs.telefone + "\n"
      // "e-mail: " + this.contexto.inputs.email + "\n"
      window.open(
        "https://api.whatsapp.com/send?phone=" + telefone + "&text=" + mensagem
      );
    },
    alterarRelatoAtivo(novoAtivo) {
      this.tela.relatos.forEach((item) => {
        item.ativo = false;
        if (item.id == novoAtivo.id) {
          item.ativo = true;
        }
      });
    },
    openUrl(url) {
      window.open(url);
    },
    scrollToElement(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    document.title = "LMS Engenharia e Construções ";
    this.inicializarContextoAcoesNavegador();
  },
  computed: {
    portifolio(){
      let portifolio = [];
      this.tela.portifolio.forEach((projeto)=>{
        projeto.forEach((item)=>{
          portifolio.push(item);
        })
      });
      return portifolio;
    },
    relatoAtivo() {
      return this.tela.relatos.find((item) => item.ativo);
    },
    isMobile() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
  },
});
</script>
<style>
.bg-mid-imagem {
  background: linear-gradient(rgba(157, 157, 157, 0.5), rgba(0, 0, 0, 0.1)),
    url("../assets/background/casa.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0);
}
.bg-formulario-imagem {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),
    url("../assets/background/casa2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0);
}
.cursor-pointer:hover {
  cursor: pointer;
}
.text-hover {
  opacity: 0.8;
}
.text-hover:hover {
  cursor: default;
  opacity: 1;
}
.text-underline:hover {
  cursor: default;
  text-decoration: underline;
}
.hover-image {
  transition: all 0.5s;
}
.hover-image:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.background-transparent {
  background-color: rgba(255, 255, 255, 0.8);
}
.circular-image {
  border-radius: 50%;
  object-fit: cover;
  filter: brightness(1.2);
}
.default-mouse:hover{
  cursor: default;
}
</style>
